export default [
  {
    path: '/home',
    name: 'home',
    meta: {
      auth: true
    },
    component: () => import('@/views/home'),
    children: [
      {
        path: 'home',
        component: () => import('@/views/nav/homepage'),
        meta: {
          auth: true,
          keepAlive: false // 需要被缓存
        }
      },
      {
        path: 'start',
        component: () => import('@/views/nav/start'),
        meta: {
          auth: true,
          keepAlive: false // 需要被缓存
        }
      },
      {
        path: 'transaction',
        component: () => import('@/views/nav/transaction'),
        meta: {
          auth: true,
          keepAlive: false // 需要被缓存
        }
      },
      {
        path: 'my',
        meta: {
          keepAlive: true // 需要被缓存
        },
        component: () => import('@/views/user'),
      },
      {
        path: 'vip',
        name: 'vip',
        meta: {
          auth: true
        },
        component: () => import('@/views/user/vip'),
      },
      {
        path: 'content',
        name: 'content',
        meta: {
          auth: true
        },
        component: () => import('@/views/common/content'),
      },
      {
        path: 'deposit',
        name: 'deposit',
        meta: {
          auth: true
        },
        component: () => import('@/views/user/deposit'),
      },
      {
        path: 'withdraw',
        name: 'withdraw',
        meta: {
          auth: true
        },
        component: () => import('@/views/user/withdraw'),
      },
      {
        path: 'walletLog',
        name: 'walletLog',
        meta: {
          auth: true
        },
        component: () => import('@/views/user/walletLog'),
      },
      {
        path: 'profile',
        name: 'profile',
        meta: {
          auth: true
        },
        component: () => import('@/views/user/profile'),
      },
      {
        path: 'bindCard',
        name: 'bindCard',
        meta: {
          auth: true
        },
        component: () => import('@/views/user/bindCard'),
      },
      {
        path: 'news',
        name: 'news',
        meta: {
          auth: true
        },
        component: () => import('@/views/user/news'),
      },
      {
        path: 'loginPassword',
        name: 'loginPassword',
        meta: {
          auth: true
        },
        component: () => import('@/views/user/loginPassword'),
      },
      {
        path: 'payPassword',
        name: 'payPassword',
        meta: {
          auth: true
        },
        component: () => import('@/views/user/payPassword'),
      },
      {
        path: 'auto',
        name: 'auto',
        meta: {
          auth: true
        },
        component: () => import('@/views/user/auto'),
      },
      {
        path: 'invitation',
        name: 'invitation',
        meta: {
          auth: true
        },
        component: () => import('@/views/user/invitation'),
      },
      {
        path: 'singin',
        name: 'singin',
        meta: {
          auth: true
        },
        component: () => import('@/views/user/singin'),
      },
    ],
  },
  {
    path: '/index',
    name: 'index',
    meta: {
      auth: false
    },
    component: () => import('@/views/index'),
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      auth: false
    },
    component: () => import('@/views/login'),
  },
  {
    path: '/register',
    name: 'register',
    meta: {
      auth: false
    },
    component: () => import('@/views/register'),
  },
  {
    path: '/term',
    name: 'term',
    meta: {
      auth: false
    },
    component: () => import('@/views/term'),
  },
  {
    path: '/contact',
    name: 'contact',
    meta: {
      auth: false
    },
    component: () => import('@/views/contact'),
  },
]
